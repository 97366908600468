const devConfig = {
  anomalyDetectionService: {
    baseUrl: 'https://solar-flares-cf-ktvkuak7uq-uc.a.run.app/solarflares',
    apiKey: 'AIzaSyBqd9zpNm4GAyoastGbs0bZd-NldKJF6Gg',
  },
  dataPlatform: {
    root: 'dev-data-platform.appspot.com',
    graphQLUri: 'https://dev-data-platform.appspot.com/noc/graphql',
    versionUri: 'https://dev-data-platform.appspot.com/versions',
  },
  hybridAuth: {
    issuer: 'auslp5hbrxpd6sAub0x7',
    url: 'https://gateway.sunrundev.com/devmaj-hybrid-auth/hybridTokens',
  },
  maps: {
    apiKey: 'AIzaSyDmZiidImwMqxxMqPW2AlzdcxsonJnvI94',
  },
  mixpanelKey: 'ebdec5c54d7f0fbfa874b8cede94d5f5',
  oktaConfigUrl: 'https://s3-us-west-2.amazonaws.com/devmaj-noc2-okta-client-config/devmajOktaClientConfig.json',
  oktaEnvironment: 'Devmaj',
  performanceApiBaseUrl: 'https://gateway.sunrundev.com/devmaj-performance-api',
  rollbar: {
    accessToken: 'f3744f87415b4179a0b1a73d35398b81',
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: true,
    payload: {
      environment: 'dev',
      client: {
        javascript: {
          source_map_enabled: true,
        },
      },
    },
    transform(payload) {
      const { trace } = payload.body;
      const locRegex = /^(https?):\/\/[a-zA-Z0-9._-]+\.(devmaj-noc2.sunrun)\.com(.*)/;
      if (trace && trace.frames) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < trace.frames.length; i++) {
          const { filename } = trace.frames[i];
          if (filename) {
            const m = filename.match(locRegex);
            // changed m[1] => m[3] because (devmaj-noc2.sunrun) creates a new match group
            trace.frames[i].filename = `${m[1]}://dynamichost${m[3]}`;
            // m[1] = (https?), m[2] = (devmaj-noc2.sunrun), m[3] = bundle name (everything after .com)
            // example result: https://dynamichost/bundle.app.8as232as.js
          }
        }
      }
    },
  },
};

export default devConfig;
