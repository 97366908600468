const localConfig = {
  anomalyDetectionService: {
    baseUrl: 'https://solar-flares-cf-m46t4v3h5q-uc.a.run.app/solarflares',
    apiKey: 'AIzaSyA6cPg4evOZTEjjxGTVdrUq0E_-TkAjb44',
  },
  dataPlatform: {
    root: 'dev-data-platform.appspot.com',
    graphQLUri: 'https://dev-data-platform.appspot.com/noc/graphql-api',
    versionUri: 'https://dev-data-platform.appspot.com/versions-api',
  },
  hybridAuth: {
    issuer: 'auslp5hbrxpd6sAub0x7',
    url: 'https://gateway.sunrundev.com/local-hybrid-auth/hybridTokens',
  },
  maps: {
    apiKey: 'AIzaSyAErcXuZ-i5rrWeP9ZAggzPNU8Ei2yVaOw',
  },
  mixpanelKey: 'ebdec5c54d7f0fbfa874b8cede94d5f5',
  oktaConfigUrl: 'http://localhost:3000/oktatestconfig.json',
  oktaEnvironment: 'Devmaj',
  performanceApiBaseUrl: 'https://gateway.sunrundev.com/local-performance-api',
  rollbar: {
    accessToken: 'f3744f87415b4179a0b1a73d35398b81',
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: false,
    payload: {
      environment: 'local',
      client: {
        javascript: {
          source_map_enabled: true,
        },
      },
    },
    transform(payload) {
      const { trace } = payload.body;
      const locRegex = /^(localhost:3000):\/\/[a-zA-Z0-9._-]+\.(.*)/;
      if (trace && trace.frames) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < trace.frames.length; i++) {
          const { filename } = trace.frames[i];
          if (filename) {
            const m = filename.match(locRegex);
            trace.frames[i].filename = `${m[1]}://dynamichost${m[2]}`;
          }
        }
      }
    },
  },
};

export default localConfig;
