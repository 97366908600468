import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { DateTime } from 'luxon';
import { withAuth } from '@okta/okta-react';
import { useQuery } from '@apollo/client';
import { PerformanceProvider, PerformanceCharts } from '@sunrun/recent-performance-charts';
import config from '../../../config';
import { ALL_ALERTS_QUERY } from '../../queries/site';
import { unwrapGraphQlList } from '../../../general/utils/utils';

const ChartWrapper = styled.section`
  margin: 20px 0;
`;

const RecentPerformanceContainer = ({
  auth,
  minDateISO,
  prospectId,
  storageProfile,
  tzName,
  hasBattery,
}) => {
  const timezone = tzName || 'local';
  const maxDateISO = DateTime.local().startOf('day', { timezone }).toISO();
  const [hybridToken, setHybridToken] = useState(null);
  const {
    data: alertData,
  } = useQuery(ALL_ALERTS_QUERY, { variables: { prospectId } }) || [];
  const alerts = unwrapGraphQlList(alertData, 'site.allAlerts.edges');
  useEffect(() => {
    const fetchHybrid = async () => {
      const accessToken = await auth.getAccessToken();
      if (accessToken) {
        const resp = await fetch(
          config.hybridAuth.url,
          {
            method: 'POST',
            body: JSON.stringify({
              tokenType: 'okta',
              token: accessToken,
              authServer: config.hybridAuth.issuer,
            }),
          },
        );
        const hybridTokenResp = await resp.json();
        setHybridToken(hybridTokenResp.hybridToken);
      }
    };
    fetchHybrid();
  }, [auth]);
  if (!hybridToken) return null;

  return (
    <PerformanceProvider>
      <ChartWrapper>
        <PerformanceCharts
          alertData={alerts}
          authToken={hybridToken}
          baseUrl={config.performanceApiBaseUrl}
          internalUsage
          maxDateISO={maxDateISO}
          minDateISO={minDateISO}
          prospectId={prospectId}
          storageProfile={storageProfile}
          timeZone={timezone}
          withBatteryChart
          hasBattery={hasBattery}
        />
      </ChartWrapper>
    </PerformanceProvider>
  );
};

RecentPerformanceContainer.propTypes = {
  auth: PropTypes.shape({
    getAccessToken: PropTypes.func,
  }).isRequired,
  minDateISO: PropTypes.string.isRequired,
  prospectId: PropTypes.string.isRequired,
  storageProfile: PropTypes.shape({
    storageMode: PropTypes.string,
    storageProfileDetails: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  tzName: PropTypes.string.isRequired,
  hasBattery: PropTypes.bool.isRequired,
};

RecentPerformanceContainer.defaultProps = {
  storageProfile: {},
};

export default withAuth(RecentPerformanceContainer);
