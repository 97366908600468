import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import styled from '@emotion/styled/macro';
import mixpanel from 'mixpanel-browser';

import {
  Card,
  SectionHeader,
  ToggleButton,
} from '../../general/components';
import { FADED_GREY } from '../../general/constants/_colors';
import AssetInformation from './AssetInformation/AssetInformation';
import History from './History';
import ActiveMeters from './ActiveMeters';

const SummaryCard = styled(Card)`
  min-height: 104px;
  position: relative;
  border: none;
`;

const SummaryCardToggle = styled(ToggleButton)`
  bottom: 20px;
  height: 30px;
  position: absolute;
  right: 20px;
  width: 30px;
`;

const SummaryHeader = styled.div`
  align-items:center;
  display:flex;
  justify-content: space-between;
  margin-bottom: 18px;
  padding-right: 15px;
  padding-top: 20px;
  height:25px;
  width: 100%;
`;

const RowContainer = styled(Row)`
  border-bottom: 1px solid #B2ADA6;
`;

function Summary({ prospectId }) {
  const ASSET_INFORMATIION = 'asset-information';
  const ALERTS = 'alerts';
  const [activeSummary, setActiveSummary] = useState(null);
  return (
    <>
      <RowContainer>
        {(!activeSummary || activeSummary === ASSET_INFORMATIION) && (
        <Col
          xs={12}
          md={activeSummary === ASSET_INFORMATIION ? 12 : 6}
          style={{
            borderRight: activeSummary === ASSET_INFORMATIION ? 'none' : `1px solid ${FADED_GREY}`,
            position: 'relative',
          }}
        >
          <SummaryHeader>
            <SectionHeader>Asset Info</SectionHeader>
            <ActiveMeters prospectId={prospectId} />
          </SummaryHeader>
          <SummaryCard>
            <AssetInformation prospectId={prospectId} isExpanded={activeSummary === ASSET_INFORMATIION} />
          </SummaryCard>
          <SummaryCardToggle
            isExpanded={activeSummary === ASSET_INFORMATIION}
            clickHandler={() => {
              mixpanel.track('Card Toggled', {
                toggle: activeSummary === ASSET_INFORMATIION ? 'collapsed' : 'expanded',
                activeSummary,
              });
              setActiveSummary(activeSummary === ASSET_INFORMATIION ? null : ASSET_INFORMATIION);
            }}
          />
        </Col>
        )}
        {(!activeSummary || activeSummary === ALERTS) && (
        <Col
          xs={12}
          md={activeSummary === ALERTS ? 12 : 6}
          style={{ position: 'relative' }}
        >
          <SummaryHeader>
            <SectionHeader />
            <History prospectId={prospectId} />
          </SummaryHeader>
        </Col>
        )}
      </RowContainer>
    </>
  );
}

Summary.propTypes = {
  prospectId: PropTypes.string.isRequired,
};

export default Summary;
